import React from 'react'

const Footer = () => {
  return (
    <footer className='bg-primary'>
      <h1 className='font-chillaxSemibold uppercase text-center pb-3'>2024 © desenvo</h1>
    </footer>
  )
}

export default Footer